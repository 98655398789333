






























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Session } from "@/core/models/sessions";

@Component
export default class ProgressHeader extends Vue {
  @Prop({ default: () => [] }) sessions!: Session[];

  options = [2, 4, 6, 42];
  optionValue = 42;
  get currentOption() {
    return this.$t("charts.points", {
      n: this.optionValue == 42 ? this.$t("charts.all") : this.optionValue,
    });
  }

  get reducedSessions(): Session[] {
    let end = this.optionValue;
    if (this.optionValue == 42) end = this.sessions.length;
    return this.sessions.slice(0, end).reverse();
  }

  @Watch("optionValue", { immediate: true })
  reduceSessions() {
    let end = this.optionValue;
    if (this.optionValue == 42) end = this.sessions.length;
    let reduced = this.sessions.slice(0, end).reverse();
    this.$emit("reduce", reduced);
  }
}
