import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(_vm.sessions.length !== 0)?_c(VRow,{staticClass:"justify-center"},[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-1 font-weight-bold mr-6"},[_vm._v(_vm._s(_vm.$t("sessionsPage.sessionsProgressSection.recentData")))]),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"px-2",attrs:{"color":"grey","dark":"","text":""}},on),[_c('span',[_vm._v(_vm._s(_vm.currentOption))])])]}}],null,false,2358464229)},[_c(VList,_vm._l((_vm.options),function(option,index){return _c(VListItem,{key:index,on:{"click":function($event){_vm.optionValue = option}}},[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(option == 42 ? _vm.$t("charts.all") : option))])])],1)}),1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }